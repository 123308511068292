import React from "react";
import styled, { css } from "styled-components";
import { typography } from "screens/Homepage/components/typography";
import { LinkMarketingButton } from "screens/Homepage/styles";

interface IProps {
  image?: string;
  title: string;
  description: string;
  docsUrl?: string;
}

const FeatureCard: React.FC<IProps> = ({ image, title, description, docsUrl }) => {
  return (
    <Container>
      {image && <Image src={image} alt={title} />}
      <TextContentContainer>
        <Title>{title}</Title>
        <Description>{description}</Description>
        {docsUrl && (
          <LinkMarketingButton
            href={docsUrl}
            icon="far-arrow-up-right"
            buttonTheme="secondary"
            variant="outline"
          >
            Explore Docs
          </LinkMarketingButton>
        )}
      </TextContentContainer>
    </Container>
  );
};

export default FeatureCard;

const Container = styled.div`
  ${({ theme }) => {
    return css`
      display: flex;
      flex-direction: column;
      padding: 0px;
      width: 100%;
      max-width: 564px;
      height: fit-content;
      background: ${theme.colors.gray2};
      border: 1px solid ${theme.colors.gray6};
      border-radius: 8px;
    `;
  }}
`;

const Image = styled.img`
  width: 100%;
  height: auto;
`;

const TextContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  gap: 8px;
  width: 100%;
`;

const Title = styled.h5`
  ${({ theme }) => {
    return css`
      all: unset;
      color: ${theme.colors.gray12};
      ${typography.title20};
    `;
  }}
`;

const Description = styled.p`
  ${({ theme }) => {
    return css`
      all: unset;
      color: ${theme.colors.gray11};
      ${typography.textBaseLight};
      margin-bottom: 8px;
    `;
  }}
`;
