import { Dispatch } from "react";
import { IIdentity, IUserInfo } from "types/interfaces/identity/IIdentity";
import { IIdentityQuota } from "types/interfaces/identity/IIdentityQuota";

export enum EAuthActionType {
  SET_USER_INFO,
  SET_IDENTITY_QUOTA,
  SET_ACTIVE_FORM,
  SET_SUBSCRIBE_BANNER_VISIBLE,
  SET_AUTH_STATE
}

export enum EAuthFormState {
  SignUp = 0,
  Login = 1,
  Confirm = 2
}

export enum EAuthState {
  PENDING,
  AUTHENTICATED,
  UNAUTHENTICATED
}

export interface IAuthContextState {
  userInfo?: IUserInfo;
  identityQuota?: IIdentityQuota;
  updateBannerVisible: boolean;
  activeForm?: EAuthFormState;
  modelList?: Array<any>;
  authState: EAuthState;
}

export type TAuthAction = {
  type: EAuthActionType;
  payload: any;
};

export interface IAuthContext {
  state: IAuthContextState;
  dispatch: Dispatch<TAuthAction>;
}
