import styled, { css } from "styled-components";
import { Input, Typography } from "@beamcloud/design-system";

import { BlueHighlightedCSS, SectionContainer, SectionTitle, SectionSubtitle } from "screens/Homepage/styles";
import { Highlights } from "./data";
import SystemImage from "assets/homepage/landing/buildingblocks/System.svg";
import NavLink from "components/NavLink";

const HighlightsSection: React.FC = () => {
  return (
    <StyledSectionContainer>
      <SectionTitle $maxWidth="640px">Built for Ambitious Workloads at Massive Scale</SectionTitle>
      <SectionSubtitle>
        Beam is powered by{" "}
        <StyledNavLink href="https://github.com/beam-cloud/beta9" newWindow>
          Beta9
        </StyledNavLink>
        , the open source container runtime purpose-built for large scale GPU workloads.
      </SectionSubtitle>
      <Image src={SystemImage} alt={"Beam is fast"} data-layout={"1"} />
    </StyledSectionContainer>
  );
};

export default HighlightsSection;

const Row = styled.div`
  ${({ theme }) => {
    return css`
      display: grid;

      grid-template-columns: 1fr 1fr 1fr;

      @media (max-width: ${theme.breakpoints.lg}px) {
        grid-template-columns: 1fr;
      }

      gap: 24px;
      width: 100%;
      max-width: 1152px;
      justify-items: center;
    `;
  }}
`;

const HighlightCard = styled.div`
  ${({ theme }) => {
    return css`
      display: flex;
      padding: 48px 32px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;
      width: 100%;
      height: auto;
      border-radius: 8px;
      border: 1px solid ${theme.colors.gray6};
      background: ${theme.colors.gray2};
    `;
  }}
`;
{
  /* 
const Metrics = styled.h3<{ $text?: string }>`
  all: unset;
  ${typography.title40};
  ${({ theme }) =>
    BlueHighlightedCSS(
      theme.colors.gray2,
      `0px 4px 48px rgba(83, 113, 231, 0.64), 0px 8px 96px rgba(83, 113, 231, 0.48), 0px 0px 4px rgba(132, 157, 255, 0.48)`
    )};
`; */
}

const Label = styled.p`
  ${({ theme }) => {
    return css`
      all: unset;
      color: ${theme.colors.gray12};
      ${theme.styleSet.textSm};
    `;
  }}
`;

const Image = styled.img`
  ${({ theme }) => css`
    align-items: center;
    height: 500px;
    width: 100%;
  `}
`;

const StyledNavLink = styled(NavLink)`
  && {
    text-decoration: underline;
    &:hover {
      text-decoration: underline;
    }
  }
`;

const StyledSectionContainer = styled(SectionContainer)`
  gap: 40px;
`;
