import React from "react";
import styled from "styled-components";
import Lottie from "react-lottie";
import animationData from "assets/loader.json";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};

const GlobalLoading = () => {
  return (
    <Wrapper>
      <StyledLottie options={defaultOptions} height={400} width={400} />
    </Wrapper>
  );
};

export default GlobalLoading;

const Wrapper = styled.div`
  position: fixed;
  display: grid;
  justify-items: center;
  align-items: center;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  background-color: ${({ theme }) => theme.colors.gray1};

  body {
    margin: 0;
    height: 100%;
    overflow: hidden;
  }
`;

const StyledLottie = styled(Lottie)``;
