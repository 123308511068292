import { Avatar, Typography } from "@beamcloud/design-system";
import { typography } from "screens/Homepage/components/typography";
import styled, { css } from "styled-components";

export interface ITestimonial {
  name: string;
  userName?: string;
  testimonial?: string | React.ReactNode;
  avatarImg?: string;
}

interface IProps extends ITestimonial {
  className?: string;
}

const TestimonialCard: React.FC<IProps> = ({ className, testimonial, name, userName, avatarImg }) => {
  return (
    <Container className={className}>
      <Info>
        <Avatar size={48} bgColor="gray9" imageSrc={avatarImg} />
        <NameWrapper>
          <Name>{name}</Name>
          {userName && <Nickname>{userName}</Nickname>}
        </NameWrapper>
      </Info>
      <Testimonial>{testimonial}</Testimonial>
    </Container>
  );
};

export default TestimonialCard;

const Container = styled.div`
  ${({ theme }) => {
    return css`
      display: inline-flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 20px 24px;
      gap: 16px;
      box-sizing: border-box;
      width: 100%;
      min-width: 276px;
      background-color: ${theme.colors.gray2};
      border: 1px solid ${theme.colors.gray6};
      border-radius: 8px;
    `;
  }}
`;

const Info = styled.div`
  ${({ theme }) => {
    return css`
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0px;
      gap: 12px;
    `;
  }}
`;

const NameWrapper = styled.div`
  ${({ theme }) => {
    return css`
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px;
    `;
  }}
`;

const Name = styled(Typography).attrs({
  tag: "h4",
  variant: "textBasePlus",
  color: "gray12"
})``;

const Nickname = styled.p`
  ${({ theme }) => {
    return css`
      all: unset;
      color: ${theme.colors.gray11} !important;
      ${typography.textBaseLight}
    `;
  }}
`;

const Testimonial = styled.p`
  ${({ theme }) => {
    return css`
      all: unset;
      color: ${theme.colors.gray12};
      ${typography.textBaseLight}
    `;
  }}
`;
