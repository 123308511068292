import React, { Suspense } from "react";
import { Route, Switch } from "react-router-dom";

// Routes
import { ROUTES } from "routes/constants";

// Components
import GlobalLoading from "components/LoadingAnimations/GlobalLoading";
import LocalLoading from "components/LoadingAnimations/LocalLoading";

// Screens
import Homepage from "screens/Homepage";
const Auth = React.lazy(() => import("screens/Auth"));
const Dashboard = React.lazy(() => import("screens/Dashboard"));

const Routes = () => {
  return (
    <Switch>
      {/* Dashboard */}
      <Route
        path={[ROUTES.beam_dashboard, ROUTES.beam_apps]}
        render={() => (
          <Suspense fallback={<LocalLoading />}>
            <Dashboard />
          </Suspense>
        )}
      />

      {/* Auth */}
      <Route
        path={[ROUTES.login, ROUTES.join, ROUTES.github_redirect]}
        render={() => (
          <Suspense fallback={<GlobalLoading />}>
            <Auth />
          </Suspense>
        )}
      />

      {/* Homepage */}
      <Route path="/" component={Homepage} />
    </Switch>
  );
};

export default Routes;
