import axios, { AxiosError, AxiosResponse } from "axios";
import * as HttpStatus from "http-status-codes";
import { toast } from "react-toastify";
import Logger from "services/logger";
import { EValidationErrorMapping, EValidationErrors } from "types/enums/EValidationErrors";
import { IServiceRequest, IServiceResponse, IServerResponseError, IServerError } from "types/interfaces/IApi";

// Services
import ConfigGlobalLoader from "../config";

// Load global config
const configGlobal = ConfigGlobalLoader.config;

export interface ApiCallback {
  updateCallsLoading: Function;
}

const BYPASS_CALLS_LOADING_PATH_LIST = [
  "sandbox",
  "sandbox-session",
  "model-job-instance",
  "model-job-template",
  "model-data-source"
];

export interface IRequestToastOptions {
  withToastOnError?: boolean;
}

class APIService {
  private static authToken: string | undefined = undefined;
  private static callbacks: ApiCallback | undefined = undefined;
  private static headers: any = {
    "Content-Type": "application/json"
  };

  public static setAuthToken(authToken: string | undefined) {
    this.authToken = authToken;
  }

  public static getFirstError<T = {}>(response: IServiceResponse<T>): IServerError | undefined {
    if (!response.errors) {
      return undefined;
    }

    const firstError = response.errors[0];

    if (!firstError) {
      return undefined;
    }

    return firstError;
  }

  public static toastFirstError<T = {}>(response: IServiceResponse<T>, onClose?: () => void) {
    const firstError = this.getFirstError(response);
    const errorPrefix = firstError?.attr ? `${firstError.attr}: ` : undefined;

    const errorMessage = (errorPrefix ?? "") + (this.getFirstError(response)?.detail ?? "Unknown error");
    toast.error(errorMessage, {
      onClose: onClose
    });
  }

  public static _ok(response: any) {
    return response.status >= 200 && response.status < 300;
  }

  protected static async _request<T = {}>(request: IServiceRequest): Promise<IServiceResponse<T>> {
    request.headers = {
      ...this.headers,
      ...request.headers,
      ...(this.authToken ? { Authorization: `identity ${this.authToken}` } : {})
    };

    try {
      const response = (await axios.request<T>(request)) as AxiosResponse<T>;

      return {
        data: response.data,
        headers: response.headers,
        status: response.status,
        ok: this._ok(response)
      };
    } catch (_error) {
      const error = _error as AxiosError<IServerResponseError>;

      Logger.error("APIService._request", error);

      if (!axios.isAxiosError(error) || !error.response) {
        return {
          status: HttpStatus.INTERNAL_SERVER_ERROR,
          ok: false
        };
      }

      return {
        errors: error.response.data?.errors,
        status: error.response.status,
        ok: this._ok(error.response)
      };
    }
  }

  protected static async _post(
    path: string,
    requestBody: any,
    basePath: string = "app",
    headers?: any,
    skipAnimation: boolean = true
  ) {
    // Set auth token if we have one
    if (headers) {
      headers = { ...headers, Authorization: `identity ${this.authToken}` };
    } else {
      this.headers = {
        ...this.headers,
        Authorization: `identity ${this.authToken}`
      };
    }

    let response = undefined;

    let shouldShowLoadingAnimation = !skipAnimation && !BYPASS_CALLS_LOADING_PATH_LIST.includes(path);
    const loadingAnimation = new LoadingAnimation(this.callbacks);

    if (shouldShowLoadingAnimation) {
      loadingAnimation.start();
    }

    try {
      response = await fetch(`${configGlobal.apiBaseUrl}/${basePath}/${path}`, {
        method: "POST",
        headers: headers ? headers : this.headers,
        body: JSON.stringify(requestBody)
      });
    } catch (e) {
      console.log("ERROR fetching ", `${configGlobal.apiBaseUrl}/${basePath}/${path}`, e);
    } finally {
      if (shouldShowLoadingAnimation) {
        loadingAnimation.stop();
      }
    }

    if (!response) {
      return undefined;
    }

    if (!this._ok(response)) {
      try {
        const responseJson = await response.clone().json();
        if (Object.keys(responseJson).length !== 0) {
          return responseJson;
        }
      } catch (error) {}
      return undefined;
    }

    if (response.status === HttpStatus.NO_CONTENT) {
      return {};
    }

    try {
      return await response.clone().json();
    } catch (error) {}

    try {
      return await response.clone().text();
    } catch (error) {}

    Logger.error(
      "APIService._post",
      JSON.stringify({
        status: response?.status,
        statusText: response?.statusText,
        url: response?.url
      })
    );

    return response;
  }

  protected static _withToastOnError(response: any) {
    if (!response) {
      toast.error(EValidationErrorMapping.default);
    } else if (response.detail && response.detail in EValidationErrorMapping) {
      toast.error(EValidationErrorMapping[response.detail as EValidationErrors]);
    } else if (response.detail) {
      toast.error(EValidationErrorMapping.default);
    }

    return response;
  }
}

class LoadingAnimation {
  private readonly _startDelayMS = 80;
  private readonly _stopDelayMS = 150;
  private _apiCallbacks: ApiCallback | undefined;
  private _loadingAnimationTimeout: ReturnType<typeof setTimeout> | undefined;
  private _hasStartedLoadingAnimation = false;

  constructor(callbacks: ApiCallback | undefined, startDelay = null, stopDelay = null) {
    this._apiCallbacks = callbacks;
    this._startDelayMS = startDelay || this._startDelayMS;
    this._stopDelayMS = stopDelay || this._stopDelayMS;
  }

  // Start the loading animation this._startDelayMS after the request is sent
  public start() {
    this._loadingAnimationTimeout = setTimeout(() => {
      this._showLoadingAnimation();
      this._hasStartedLoadingAnimation = true;
    }, this._startDelayMS);
  }

  // Stop the loading animation this._stopDelayMS after stop() is called, if was started
  public stop() {
    if (this._loadingAnimationTimeout) {
      clearTimeout(this._loadingAnimationTimeout);
    }

    if (this._hasStartedLoadingAnimation) {
      setTimeout(() => {
        this._hideLoadingAnimation();
      }, this._stopDelayMS);
    }
  }

  // Underlying implementation for showing the loading animation
  private _showLoadingAnimation() {
    this._apiCallbacks?.updateCallsLoading(1);
  }

  // Underlying implementation for hiding the loading animation
  private _hideLoadingAnimation() {
    this._apiCallbacks?.updateCallsLoading(-1);
  }
}

export default APIService;
