import ConfigGlobalLoader from "services/config";
import BaseAPI from "../base";
import { ETutorialStepNames } from "types/enums/tutorial/ETutorialStepNames";
import { IIdentityQuota } from "types/interfaces/identity/IIdentityQuota";
import { IServiceResponse } from "types/interfaces/IApi";

const configGlobal = ConfigGlobalLoader.config;

class IdentityAPI extends BaseAPI {
  public static async Login(email: string, password: string) {
    return await this._post("login", {
      email,
      password
    });
  }

  public static async Signup(email: string, token?: string | undefined, anonId?: string | undefined) {
    const invite_token = token ? { token } : undefined;
    const anon_identity_id = anonId ? anonId : undefined;
    return await this._post(
      "email-auth-signup",
      {
        email,
        invite_token,
        anon_identity_id
      },
      "app",
      null,
      false
    );
  }

  public static async ConfirmLogin(email: string, code: string) {
    return await this._post(
      "email-auth-login",
      {
        email,
        code
      },
      "app",
      null,
      false
    );
  }

  public static async RequestLoginToken(email: string, orgName: string | null) {
    let _orgName = orgName;
    if (!_orgName) {
      //passed no org name, using email
      _orgName = email;
    }

    return await this._post(
      "email-auth-request-login-token",
      {
        email,
        org_name: _orgName
      },
      "app",
      null,
      false
    );
  }

  public static async InviteUser(email: string, message: string) {
    return await this._post("invite_user", {
      action: "create",
      email,
      message
    });
  }

  public static async ListInvitedUsers() {
    return await this._post("invite_user", {
      action: "list"
    });
  }

  public static async GetUser() {
    return await this._post("user", {
      action: "retrieve"
    });
  }

  public static async UpdateUser(
    email?: string,
    firstName?: string | null,
    lastName?: string | null,
    completed_tutorial_steps?: Record<ETutorialStepNames, boolean> | null
  ) {
    return await this._post("user", {
      action: "update",
      email: email,
      first_name: firstName,
      last_name: lastName,
      completed_tutorial_steps: completed_tutorial_steps
    });
  }

  public static async DeleteUser(id: string) {
    return await this._post("user", {
      action: "delete",
      identity_user_to_remove: id
    });
  }

  public static async ListUsers() {
    return await this._post("user", {
      action: "list"
    });
  }

  public static async ListApiKeys(keyType: string) {
    return await this._post("key", {
      action: "list",
      key_type: keyType
    });
  }

  public static async CreateApiKey(keyType: string) {
    return await this._post("key", {
      action: "create",
      key_type: keyType
    });
  }

  public static async RequestGithubAuthUrl(stateVerifier: string, beam: boolean = true) {
    return await this._post("github-auth", {
      action: "create",
      state: stateVerifier,
      beam
    });
  }

  public static async GithubTokenExchange(
    token: string,
    org_token: string | undefined = undefined,
    anonId?: string | undefined,
    beam: boolean = true
  ) {
    const anon_identity_id = anonId ? anonId : undefined;
    return await this._post("github-auth", {
      action: "create",
      code: token,
      org_token,
      anon_identity_id,
      beam
    });
  }

  public static async ListSecrets() {
    return await this._post("identity_secret", { action: "list" });
  }

  public static async CreateSecret(data: { name: string; value: string }) {
    return await this._post("identity_secret", {
      action: "create",
      ...data
    });
  }

  public static async DeleteSecret(id: string) {
    return await this._post("identity_secret", {
      action: "delete",
      id
    });
  }

  public static async ExpireAuthTokens() {
    return await this._post("signout", {});
  }

  public static async GetIdentityQuota(): Promise<IServiceResponse<IIdentityQuota>> {
    return this._request({
      method: "GET",
      baseURL: `${configGlobal.beamApiBaseUrl}`,
      url: `identity-quota/retrieve-by-identity/`
    });
  }
}

export default IdentityAPI;
