import styled, { css } from "styled-components";
import { Typography } from "@beamcloud/design-system";
import { LinkMarketingButton, SectionContainer, SectionTitle } from "screens/Homepage/styles";
import { InnerContainer } from "screens/Homepage/components/Footer";
import Grid from "assets/homepage/landing/Grid.svg";
import { hexToRgba } from "utils/ColorUtil";
import { dashboardLink } from "constants/Links";

const BannerSection: React.FC = () => {
  return (
    <BannerContainer>
      <BannerWrapper>
        <BannerTitle>Deploy to production in minutes</BannerTitle>
        <BannerSubTitle tag="p" variant="textLg" color="gray11">
          Sign up now and get started with 15 hours of free credit
        </BannerSubTitle>
        <BannerActions>
          <LinkMarketingButton text="Get Started" size="lg" href={dashboardLink} />
        </BannerActions>
      </BannerWrapper>
    </BannerContainer>
  );
};

export default BannerSection;

const BannerContainer = styled(SectionContainer)`
  ${({ theme }) => {
    return css`
      background-image: url(${Grid}),
        linear-gradient(
          90deg,
          rgba(23, 24, 28, 1) 0%,
          rgba(23, 24, 28, 0) 33.33%,
          rgba(23, 24, 28, 0) 66.67%,
          rgba(23, 24, 28, 1) 100%
        ),
        linear-gradient(
          180deg,
          rgba(23, 24, 28, 0) 0%,
          rgba(23, 24, 28, 0.3) 33.33%,
          rgba(23, 24, 28, 0.8) 66.67%,
          rgba(23, 24, 28, 1) 100%
        ),
        radial-gradient(
          50% 50% at 50% 0%,
          ${hexToRgba(theme.colors.blue9, 0.8)} 0%,
          ${hexToRgba(theme.colors.blue9, 0)} 100%
        ),
        conic-gradient(
          from -90deg at 50% 0%,
          ${hexToRgba(theme.colors.blue9, 0)} 0deg,
          ${hexToRgba(theme.colors.blue9, 0.8)} 180deg,
          ${hexToRgba(theme.colors.blue9, 0.5)} 270deg,
          ${hexToRgba(theme.colors.blue9, 0)} 360deg
        ),
        conic-gradient(
          from 90deg at 50% 0%,
          ${hexToRgba(theme.colors.blue9, 0)} 0deg,
          ${hexToRgba(theme.colors.blue9, 0.5)} 90deg,
          ${hexToRgba(theme.colors.blue9, 0.8)} 180deg,
          ${hexToRgba(theme.colors.blue9, 0)} 360deg
        );
      background-size: cover, 400px 480px, 400px 480px, 120px 320px, 200px 480px, 200px 480px;
      background-position: center top 25%, center top, center top, center top, top left calc(100% / 2 - 100px),
        top left calc(100% / 2 + 100px);
      background-repeat: no-repeat;
    `;
  }}
`;

const BannerWrapper = styled(InnerContainer)`
  ${({ theme }) => {
    return css`
      padding: 48px;
      box-sizing: border-box;
      background: ${hexToRgba(theme.colors.gray3, 0.64)};
      backdrop-filter: blur(8px);
      border-radius: 8px;
    `;
  }}
`;

const BannerTitle = styled(SectionTitle)`
  text-align: center;
  max-width: 100%;
  margin-bottom: 20px;
`;

const BannerSubTitle = styled(Typography)`
  && {
    ${({ theme }) => css`
      ${theme.styleSet.textLg}

      @media (max-width: ${theme.breakpoints.md}px) {
        ${theme.styleSet.textBase}
      }

      margin-bottom: 48px;
    `}
  }
`;

const BannerActions = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  padding: 0px;
  gap: 16px;
`;
