import { useReducer } from "react";
import { AuthReducer } from "./AuthReducer";
import AuthContext from "./AuthContext";
import { EAuthState, IAuthContextState } from "types/interfaces/IAuth";

interface Props {
  children: React.ReactNode;
}

export const DEFAULT_STATE: IAuthContextState = {
  userInfo: undefined,
  identityQuota: undefined,
  updateBannerVisible: false,
  activeForm: undefined,
  modelList: undefined,
  authState: EAuthState.PENDING
};

const AuthProvider = ({ children }: Props) => {
  const [state, dispatch] = useReducer(AuthReducer, DEFAULT_STATE);

  return <AuthContext.Provider value={{ state, dispatch }}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
