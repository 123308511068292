import { EAuthActionType, TAuthAction, IAuthContextState } from "types/interfaces/IAuth";

export function AuthReducer(state: IAuthContextState, action: TAuthAction): IAuthContextState {
  switch (action.type) {
    case EAuthActionType.SET_SUBSCRIBE_BANNER_VISIBLE: {
      return {
        ...state,
        updateBannerVisible: action.payload
      };
    }
    case EAuthActionType.SET_USER_INFO: {
      return {
        ...state,
        userInfo: action.payload
      };
    }
    case EAuthActionType.SET_IDENTITY_QUOTA: {
      return {
        ...state,
        identityQuota: action.payload
      };
    }
    case EAuthActionType.SET_ACTIVE_FORM: {
      return {
        ...state,
        activeForm: action.payload
      };
    }
    case EAuthActionType.SET_AUTH_STATE: {
      return {
        ...state,
        authState: action.payload
      };
    }
    default: {
      return state;
    }
  }
}
