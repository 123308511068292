import { Helmet } from "react-helmet";
import Hero from "./Hero";
import BuildingBlocks from "./BuildingBlocks";
import LaunchSection from "./LaunchSection";
import FeaturesSection from "./FeaturesSection";
import TestimonialsSection from "./TestimonialsSection";
import BannerSection from "../components/BannerSection";
import HighlightsSection from "./HighlightsSection";
import InvestorSection from "./Hero/InvestorSection";

interface IProps {}

const BeamPage: React.ComponentType<IProps> = () => {
  return (
    <>
      <Helmet>
        <title>The Serverless Cloud for Busy Developers • Beam</title>
      </Helmet>
      <Hero />
      <InvestorSection />
      <BuildingBlocks />
      <FeaturesSection />
      <HighlightsSection />
      <LaunchSection />
      <TestimonialsSection />
      <BannerSection />
    </>
  );
};

export default BeamPage;
