import { createContext, Dispatch, SetStateAction, useContext } from "react";

interface IGlobalContext {
  accessToken?: string;
  setAccessToken: Dispatch<string>;
  removeAccessToken: () => void;
  pendingApiCalls?: number;
  setPendingApiCalls: Dispatch<SetStateAction<number>>;
}

export const GlobalContext = createContext<IGlobalContext>({
  setAccessToken: () => {},
  removeAccessToken: () => {},
  setPendingApiCalls: () => {}
});

export const useGlobalContext = () => {
  return useContext(GlobalContext);
};
