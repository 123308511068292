import React from "react";
import styled from "styled-components";

interface IProps {
  loadingText?: string;
}

const LocalLoading: React.FC<IProps> = ({ loadingText = "Beaming down your data..." }) => {
  return (
    <Container>
      <Boxes>
        <Box1>
          <Div />
          <Div />
          <Div />
          <Div />
        </Box1>
        <Box2>
          <Div />
          <Div />
          <Div />
          <Div />
        </Box2>
        <Box3>
          <Div />
          <Div />
          <Div />
          <Div />
        </Box3>
        <Box4>
          <Div />
          <Div />
          <Div />
          <Div />
        </Box4>
      </Boxes>
      <LoadingText>{loadingText}</LoadingText>
    </Container>
  );
};

export default LocalLoading;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

const LoadingText = styled.div`
  margin-top: 128px;
  color: ${props => props.theme.colors.gray11};
`;

const Boxes = styled.div`
  --size: 32px;
  --duration: 800ms;
  height: calc(var(--size) * 2);
  width: calc(var(--size) * 3);
  position: relative;
  transform-style: preserve-3d;
  transform-origin: 50% 50%;
  margin-top: calc(var(--size) * 1.5 * -1);
  transform: rotateX(60deg) rotateZ(45deg) rotateY(0deg) translateZ(0px);
`;

const Div = styled.div`
  --background: #3170f7;
  --top: auto;
  --right: auto;
  --bottom: auto;
  --left: auto;
  --translateZ: calc(var(--size) / 2);
  --rotateY: 0deg;
  --rotateX: 0deg;
  position: absolute;
  width: 100%;
  height: 100%;
  background: var(--background);
  top: var(--top);
  right: var(--right);
  bottom: var(--bottom);
  left: var(--left);
  transform: rotateY(var(--rotateY)) rotateX(var(--rotateX)) translateZ(var(--translateZ));
`;

const Box = styled.div`
  width: var(--size);
  height: var(--size);
  top: 0;
  left: 0;
  position: absolute;
  transform-style: preserve-3d;

  ${Div}:nth-child(1) {
    --top: 0;
    --left: 0;
  }
  ${Div}:nth-child(2) {
    --background: #114bc7;
    --right: 0;
    --rotateY: 90deg;
  }
  ${Div}:nth-child(3) {
    --background: #1c52c6;
    --rotateX: -90deg;
  }
  ${Div}:nth-child(4) {
    --background: #2a3b60;
    --top: 0;
    --left: 0;
    --translateZ: calc(var(--size) * 3 * -1);
  }
`;

const Box1 = styled(Box)`
  transform: translate(100%, 0);
  animation: box1 var(--duration) linear infinite;
  @keyframes box1 {
    0%,
    50% {
      transform: translate(100%, 0);
    }
    100% {
      transform: translate(200%, 0);
    }
  }
`;

const Box2 = styled(Box)`
  transform: translate(0, 100%);
  animation: box2 var(--duration) linear infinite;
  @keyframes box2 {
    0% {
      transform: translate(0, 100%);
    }
    50% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(100%, 0);
    }
  }
`;

const Box3 = styled(Box)`
  transform: translate(100%, 100%);
  animation: box3 var(--duration) linear infinite;
  @keyframes box3 {
    0%,
    50% {
      transform: translate(100%, 100%);
    }
    100% {
      transform: translate(0, 100%);
    }
  }
`;

const Box4 = styled(Box)`
  transform: translate(200%, 0);
  animation: box4 var(--duration) linear infinite;
  @keyframes box4 {
    0% {
      transform: translate(200%, 0);
    }
    50% {
      transform: translate(200%, 100%);
    }
    100% {
      transform: translate(100%, 100%);
    }
  }
`;
