import React, { useEffect, useState } from "react";
import { Link, useHistory, useRouteMatch } from "react-router-dom";
import styled, { css } from "styled-components";
import DesktopLogo from "assets/beam/beam-logo.svg";
import NavLink from "components/NavLink";
import { Button, Icon, defaultTheme as theme } from "@beamcloud/design-system";
import { ROUTES } from "routes/constants";
import useWindowSize from "utils/useWindowSize";
import navItems from "./NavItems";
import ActionComponents from "./ActionComponents";

interface HeaderProps {}

const Header: React.FC<HeaderProps> = () => {
  const match = useRouteMatch();
  const [isMobile, setIsMobile] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { width } = useWindowSize();
  const history = useHistory();

  const handleSearch = (value: string) => {};

  const onSignIn = () => {
    // localStorage.setItem("authRedirectURI", JSON.stringify(`${ROUTES.beam_dashboard}/apps`));
    history.push(`${ROUTES.login}`);
  };

  const onGetStarted = () => {
    // localStorage.setItem("authRedirectURI", JSON.stringify(`${ROUTES.beam_dashboard}/apps`));
    history.push(`${ROUTES.login}`);
  };

  useEffect(() => {
    if (width! <= (theme.breakpoints.md ?? 768)) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [width]);

  useEffect(() => {
    history.listen(() => setIsMenuOpen(false));
  }, [history]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <>
      <Container>
        <NavigationWrapper $isMobile={isMobile}>
          <NavigationHeader>
            <IconLink to={`${match.url}`}>
              <Logo src={DesktopLogo} alt="beam-logo" />
            </IconLink>
          </NavigationHeader>
          {isMobile && (
            <Button buttonTheme="secondary" variant="outline" onClick={toggleMenu}>
              <Icon size={18} icon={isMenuOpen ? "far-multiply" : "far-bars"} color="white" />
            </Button>
          )}
        </NavigationWrapper>
        <NavBar $isMobile={isMobile} $open={isMenuOpen}>
          <NavItemList>
            {navItems.map((item, index) => (
              <ListItem key={`item-${index}`}>
                <NavItem href={item.link}>{item.name}</NavItem>
              </ListItem>
            ))}
          </NavItemList>
          <ActionComponents
            handleSearch={handleSearch}
            handleSignIn={onSignIn}
            handleGetStarted={onGetStarted}
            isMobile={isMobile}
          />
        </NavBar>
      </Container>
      {isMobile && isMenuOpen && <BlurredBackground />}
    </>
  );
};

export default Header;

const Container = styled.header`
  position: sticky;
  top: 0; // This ensures the header sticks to the top of the viewport
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 48px;
  border-bottom: 1px solid ${props => props.theme.colors.gray7};
  background-color: ${props => props.theme.colors.gray1};
  width: 100vw;
  height: 60px;
  z-index: 15; // Ensure this is high enough to stay above other content

  ${({ theme }) => css`
    padding: 0 64px;

    @media (max-width: ${theme.breakpoints.xl}px) {
      padding: 0px 32px;
    }

    @media (max-width: ${theme.breakpoints.lg}px) {
      padding: 0px 24px;
    }

    @media (max-width: ${theme.breakpoints.md}px) {
      padding: 0px 16px;
    }
  `}
`;

const NavigationWrapper = styled.div<{ $isMobile: boolean }>`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: ${({ $isMobile }) => ($isMobile ? "100%" : "max-content")};
  background-color: inherit;
`;

const NavigationHeader = styled.h1`
  margin: 0;
`;

const IconLink = styled(Link)`
  && {
    width: fit-content;
    height: fit-content;
  }
`;

const Logo = styled.img`
  height: 25px;
  width: auto;
`;

const NavItemList = styled.ul`
  all: unset;
`;

const ListItem = styled.li`
  all: unset;
`;

const NavItem = styled(NavLink)`
  && {
    ${({ theme }) => css`
      text-decoration: none;

      ${theme.styleSet.textSm};

      color: ${theme.colors.gray11};
      cursor: pointer;

      &:hover {
        color: ${theme.colors.gray12};
      }

      &:active {
        color: ${theme.colors.gray12};
      }
    `}
  }
`;

const NavBar = styled.nav<{ $isMobile: boolean; $open: boolean }>`
  background-color: inherit;
  ${({ $isMobile, $open }) => {
    if ($isMobile) {
      return css`
        position: absolute;
        display: flex;
        flex-direction: column;
        gap: 24px;
        top: 100%;
        left: 0;
        right: 0;
        padding: 0 64px;
        padding-bottom: 20px;
        border-top: 1px solid ${props => props.theme.colors.gray7};
        overflow-y: auto;
        z-index: -2;
        opacity: 1;
        transition: transform ease-in-out 100ms, opacity ease-in-out 100ms, visibility ease-in-out 100ms;
        will-change: transform, opacity, visibility;
        ${!$open &&
          css`
            transform: translateY(-100%);
            opacity: 0;
            visibility: hidden;
          `}

        ${NavItemList} {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
        }

        ${ListItem} {
          padding: 12px 0;

          ${NavItem} {
            width: 100%;
          }
        }
      `;
    }

    return css`
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      ${NavItemList} {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 24px;
      }
    `;
  }}
`;

const BlurredBackground = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 11;
  top: 60px;
  left: 0;
  bottom: 0;
  backdrop-filter: blur(16px);
`;
