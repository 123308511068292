import { nanoid } from "nanoid";
import { getCookie, setCookie } from "typescript-cookie";

const COOKIE_NAME = "IDENTITY_KEY";

export default class IdentityKey {
  // Get the current identity key if one exists, creating and storing a new one otherwise
  public static get(): string {
    let identityKey = getCookie(COOKIE_NAME);
    if (identityKey === undefined) {
      identityKey = nanoid();
      setCookie(COOKIE_NAME, identityKey);
    }

    return identityKey;
  }
}
