import styled, { css } from "styled-components";
import { SectionContainer, SectionTitle } from "screens/Homepage/styles";
import TestimonialCard from "./TestimonialCard";
import testimonials from "./data";

const TestimonialsSection: React.FC = () => {
  return (
    <SectionContainer>
      <SectionTitle $maxWidth="100%">Join Our Community</SectionTitle>
      <Row>
        {testimonials.map((testimonial, key) => (
          <TestimonialCard key={key} {...testimonial} />
        ))}
      </Row>
    </SectionContainer>
  );
};

export default TestimonialsSection;

const Row = styled.div`
  ${({ theme }) => {
    return css`
      display: block;
      columns: auto 3;
      column-gap: 24px;
      row-gap: 24px;
      column-fill: balance;
      width: 100%;
      max-width: 1152px;
      margin: 0 auto;

      @media (max-width: ${theme.breakpoints.lg}px) {
        columns: auto 2;
      }

      @media (max-width: ${theme.breakpoints.sm}px) {
        columns: auto 1;
      }

      & > div {
        margin-bottom: 24px;
      }
    `;
  }}
`;
