import { useCallback, useContext } from "react";
import AuthContext from "./AuthContext";
import IdentityAPI from "services/api/identity";
import { ESubscriptionType } from "types/enums/ESubscriptionType";
import { EAccountType } from "types/enums/EAccountType";
import { IUserInfo } from "types/interfaces/identity/IIdentity";
import { IIdentityQuota } from "types/interfaces/identity/IIdentityQuota";
import { EAuthActionType, EAuthFormState, EAuthState } from "types/interfaces/IAuth";

const useAuthContext = () => {
  const { state, dispatch } = useContext(AuthContext);

  // Reducers

  const setAuthState = useCallback(
    (identityState: EAuthState) => {
      dispatch({
        type: EAuthActionType.SET_AUTH_STATE,
        payload: identityState
      });
    },
    [dispatch]
  );

  const setUserInfo = useCallback(
    (userInfo: IUserInfo | undefined) => {
      dispatch({
        type: EAuthActionType.SET_USER_INFO,
        payload: userInfo
      });
    },
    [dispatch]
  );

  const setActiveForm = useCallback(
    (activeForm: EAuthFormState | undefined) => {
      dispatch({
        type: EAuthActionType.SET_ACTIVE_FORM,
        payload: activeForm
      });
    },
    [dispatch]
  );

  const setIdentityQuota = useCallback(
    (identityQuota: IIdentityQuota | undefined) => {
      dispatch({
        type: EAuthActionType.SET_IDENTITY_QUOTA,
        payload: identityQuota
      });
    },
    [dispatch]
  );

  const toggleUpdateBanner = useCallback(
    (visible: boolean) => {
      dispatch({
        type: EAuthActionType.SET_SUBSCRIBE_BANNER_VISIBLE,
        payload: visible
      });
    },
    [dispatch]
  );

  const getUserInfo = useCallback(async () => {
    setAuthState(EAuthState.PENDING);

    const response = await IdentityAPI.GetUser();

    if (response !== undefined && !response?.detail) {
      const userInfoObject = {
        userInfo: response["identity"],
        identityUser: response["identity_user"],
        integrations: {
          google_drive: response["identity_user_google_auth"]
        },
        subscription: response["identity_subscription"],
        trialTimeRemaining: response["trial_time_remaining"],
        totalTrialTimeAvailable: response["total_trial_time_available"]
      };

      setUserInfo(userInfoObject);

      // Record the user in Atlas
      const identityUser = userInfoObject?.identityUser;

      // Initialize Atlas for Customer Support
      const token = JSON.parse(String(localStorage.getItem("authToken")));
      const env = process.env.REACT_APP_ENV;

      if (identityUser?.id) {
        window.Atlas.call("identify", {
          userId: identityUser?.id,
          name: "",
          email: identityUser?.email,
          customFields: {
            account_type: userInfoObject?.subscription.subscription_type,
            auth_token: token,
            env: env
          }
        });
      }

      // If the user is still in the trial phase, show the subscribe banner
      const subscription = userInfoObject.subscription;
      if (subscription?.subscription_type === ESubscriptionType.TRIAL) {
        toggleUpdateBanner(true);
      }
      setAuthState(EAuthState.AUTHENTICATED);
      return userInfoObject;
    } else {
      setAuthState(EAuthState.UNAUTHENTICATED);
      setUserInfo(undefined);
      return null;
    }
  }, [setAuthState, setUserInfo, toggleUpdateBanner]);

  const getIdentityQuota = useCallback(async () => {
    const response: any = await IdentityAPI.GetIdentityQuota();
    if (response.ok) {
      setIdentityQuota(response.data);
    }
  }, [setIdentityQuota]);

  const isLoggedIn = state.userInfo && state.userInfo.userInfo;

  return {
    userInfo: state.userInfo,
    identityQuota: state.identityQuota,
    modelList: state.modelList,
    updateBannerVisible: state.updateBannerVisible,
    activeForm: state.activeForm,
    authState: state.authState,
    authType: {
      isLoggedIn,
      isAnon: isLoggedIn && state.userInfo?.userInfo?.account_type === EAccountType.ANON,
      isTrial:
        isLoggedIn &&
        (state.userInfo?.userInfo?.account_type === EAccountType.TRIAL ||
          state.userInfo?.userInfo?.account_type === EAccountType.ANON),
      isDeveloper: isLoggedIn && state.userInfo?.userInfo?.account_type === EAccountType.DEVELOPER,
      isTeam: isLoggedIn && state.userInfo?.userInfo?.account_type === EAccountType.TEAM
    },
    setActiveForm,
    getIdentityQuota,
    getUserInfo,
    toggleUpdateBanner
  };
};

export default useAuthContext;
