export enum EValidationErrors {
  USER_EXISTS = "user_exists",
  NOT_ADMIN = "not_admin",
  USER_ALREADY_IN_ORGANIZATION = "user_already_in_organization",
  REQUIRES_TEAM_PLAN = "requires_multiuser_identity",
  INVALID_S3_CONFIGURATION = "invalid_s3_configuration",
  DUPLICATE_DATA_INTEGRATION_NAME = "duplicate_data_integration_name",
  IDENTITY_SECRET_ALREADY_EXISTS = "identity_secret_already_exists",
  INVALID_GOOGLE_CREDENTIALS = "invalid_google_credentials",
  GOOGLE_CLOUD_EXCEPTION = "google_cloud_exception",
  INVALID_QUERY = "invalid_query",
  QUERY_DOES_EXIST = "query_does_exist",
  DATA_INTEGRATION_IN_USE = "data_integration_in_use",
  INVALID_DATA_SOURCE_NAME = "invalid_data_source_name",
  INVALID_SECRET_NAME = "invalid_secret_name",
  NAME_TOO_LONG = "name_too_long",
  INVALID_GIT_REPO = "invalid_git_repo",
  CONTAINS_PRIVATE_GIT_SUBMODULE = "contains_private_git_submodule",
  GITHUB_REPOSITORY_PRIVATE = "github_repository_private",
  INVALID_SEAT_COUNT = "invalid_seat_count",
  NOT_AN_ADMIN = "not_an_admin",
  CANNOT_REMOVE_ADMIN = "cannot_remove_admin",
  CARD_DECLINED = "card_declined",
  CANCELLED_SUBSCRIPTION_CANNOT_BE_UPDATED = "cancelled_subscription_cannot_be_updated",
  TRIALING_ACCOUNT_MUST_SUBSCRIBE = "trialing_account_must_subscribe"
}

export const EValidationErrorMapping = {
  default: "Server Error: Request Failed",
  [EValidationErrors.USER_EXISTS]: "User already exists",
  [EValidationErrors.NOT_ADMIN]: "You don't have admin priviledges",
  [EValidationErrors.USER_ALREADY_IN_ORGANIZATION]: "The user is already in the organization",
  [EValidationErrors.REQUIRES_TEAM_PLAN]: "You are not signed up for the Team plan",
  [EValidationErrors.INVALID_S3_CONFIGURATION]: "Invalid S3 Configuration",
  [EValidationErrors.DUPLICATE_DATA_INTEGRATION_NAME]: "Duplicate configuration name",
  [EValidationErrors.IDENTITY_SECRET_ALREADY_EXISTS]: "A secret with this name already exists",
  [EValidationErrors.INVALID_GOOGLE_CREDENTIALS]: "Invalid Service Account Credentials",
  [EValidationErrors.GOOGLE_CLOUD_EXCEPTION]: "GCP Exception",
  [EValidationErrors.INVALID_QUERY]: "Invalid Query",
  [EValidationErrors.QUERY_DOES_EXIST]: "Query not found",
  [EValidationErrors.DATA_INTEGRATION_IN_USE]: "This data integration is in use",
  [EValidationErrors.INVALID_DATA_SOURCE_NAME]: 'Name cannot contain spaces and characters ,*:()"/><?|\\',
  [EValidationErrors.INVALID_SECRET_NAME]:
    "Secret name or value is invalid: Must be a valid UNIX environment variable name",
  [EValidationErrors.NAME_TOO_LONG]: "Secret name should be less than 128 characters",
  [EValidationErrors.INVALID_GIT_REPO]:
    "Please make sure the git URL is valid and the repository is not private",
  [EValidationErrors.CONTAINS_PRIVATE_GIT_SUBMODULE]:
    "This sandbox contains a private git repository. Unable to complete action.",
  [EValidationErrors.GITHUB_REPOSITORY_PRIVATE]: "Cannot import a private git repo to a public sandbox",
  [EValidationErrors.INVALID_SEAT_COUNT]: "You must have at most 1 seat to switch to the Developer plan",
  [EValidationErrors.NOT_AN_ADMIN]: "You must be an admin to perform this action",
  [EValidationErrors.CANNOT_REMOVE_ADMIN]: "You cannot remove an admin from the organization",
  [EValidationErrors.CARD_DECLINED]: "Your card was declined",
  [EValidationErrors.CANCELLED_SUBSCRIPTION_CANNOT_BE_UPDATED]:
    "Your subscription has been cancelled so card cannot be updated. Please resubscribe to a new plan.",
  [EValidationErrors.TRIALING_ACCOUNT_MUST_SUBSCRIBE]:
    "Trial accounts must subscribe to a plan before updating their card"
};

export const getResponseError = (response: any) => {
  if (!response || !(response.detail in EValidationErrorMapping)) {
    return EValidationErrorMapping.default;
  }

  return EValidationErrorMapping[response.detail as EValidationErrors];
};
