import React from "react";
import NavLink from "components/NavLink";
import { Button, IButtonProps } from "@beamcloud/design-system";
import styled from "styled-components";

interface ILinkButtonProps extends IButtonProps {
  href: string;
  reload?: boolean;
  newWindow?: boolean;
  className?: string;
  outerWidth?: string;
}

const LinkButton: React.FC<ILinkButtonProps> = ({
  href,
  reload = false,
  newWindow = false,
  className,
  outerWidth = "fit-content",
  ...props
}) => {
  return (
    <StyledNavLink href={href} reload={reload} newWindow={newWindow} $outerWidth={outerWidth}>
      <Button className={className} {...props} />
    </StyledNavLink>
  );
};

export default LinkButton;

const StyledNavLink = styled(NavLink)<{ $outerWidth: string }>`
  width: ${props => props.$outerWidth};
`;
