export const youtubeLink = "https://www.youtube.com/embed/Ipp9zGOnmn4";
// Note: This link is required to be hardcoded in the Demo Button Click metric in launch darkly. Sync updates there
export const CalendlyLink = "https://calendly.com/elimernit/30min";
export const docsLink = "https://docs.beam.cloud";
export const dashboardLink = "https://platform.beam.cloud";
export const slackLink =
  "https://join.slack.com/t/beam-cloud/shared_invite/zt-2f16bwiiq-oP8weCLWNrf_9lJZIDf0Fg";
export const statusLink = "https://status.beam.cloud";
export const privacyLink = "https://docs.beam.cloud/security/privacy-policy";
export const termsLink = "https://docs.beam.cloud/security/terms-and-conditions";
export const jobsLink = "https://www.ycombinator.com/companies/beam/jobs";
export const githubLink = "https://github.com/beam-cloud/beta9/";
