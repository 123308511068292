import React from "react";
import styled, { css } from "styled-components";
import InvestorsCarousel from "screens/Homepage/components/InvestorsCarousel";

const InvestorSection: React.FC = () => {
  return (
    <Container>
      <InvestorsCarousel />
    </Container>
  );
};

export default InvestorSection;

const Container = styled.div`
  ${({ theme }) => css`
    position: relative;
    background: ${theme.colors.gray1};
    padding-top: 64px;
    padding-bottom: 96px;
  `}
`;
