import styled, { css } from "styled-components";
import WorldMap from "assets/homepage/landing/WorldMap.svg";
import { typography } from "screens/Homepage/components/typography";
import { SectionContainer, SectionSubtitle, SectionTitle } from "screens/Homepage/styles";
import { Icon } from "@beamcloud/design-system";

const LaunchSection: React.FC = () => {
  return (
    <Container>
      <TitleWrapper>
        <StyledTitle>Your trusted partner for production</StyledTitle>
        <SectionSubtitle>
          Beam is the infrastructure provider for some of the world's fastest growing AI products. We are
          built to scale with companies who need performance, control, and reliability.
        </SectionSubtitle>
      </TitleWrapper>
      <CardGrid>
        <Card>
          <IconBox>
            <Icon icon="fad-comments" size={16} color="gray12" />
          </IconBox>
          <MainInfo>
            <CardTitle>Fast Support</CardTitle>
            <CardDescription>
              We're really active in our Slack Community. If you have any questions, we'll reply fast.
            </CardDescription>
          </MainInfo>
        </Card>
        <Card>
          <IconBox>
            <Icon icon="fad-rocket-launch" size={16} color="gray12" />
          </IconBox>
          <MainInfo>
            <CardTitle>Autoscaling</CardTitle>
            <CardDescription>
              Scale to infinity, scale back to zero. We run a lot of apps in production, so we know what you
              need.
            </CardDescription>
          </MainInfo>
        </Card>
        <Card>
          <IconBox>
            <Icon icon="fad-bars-staggered" size={16} color="gray12" />
          </IconBox>
          <MainInfo>
            <CardTitle>Logging and Monitoring</CardTitle>
            <CardDescription>
              Container logs, cold start metrics, latency profiling, and more. It's all in the dashboard.
            </CardDescription>
          </MainInfo>
        </Card>
      </CardGrid>
    </Container>
  );
};

export default LaunchSection;

const Container = styled(SectionContainer)`
  ${({ theme }) => {
    return css`
      position: relative;
      z-index: 0;
      padding: 80px 16px 160px;
      gap: 64px;

      @media (min-width: ${theme.breakpoints.sm}px) {
        padding: 96px 32px 192px;
        gap: 80px;
      }

      @media (min-width: ${theme.breakpoints.md}px) {
        padding: 96px 48px 192px;
        gap: 96px;
      }

      @media (min-width: ${theme.breakpoints.lg}px) {
        padding: 96px 32px 192px;
      }

      @media (min-width: ${theme.breakpoints.xl}px) {
        padding: 96px 64px;
      }

      &:before {
        content: "";
        position: absolute;
        z-index: -1;
        right: 0;
        left: 0;
        margin: auto;
        height: 450px;
        width: 100%;
        max-width: 960px;
        min-width: 640px;
        background-image: url(${WorldMap});
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;

        bottom: -200px;

        @media (max-width: ${theme.breakpoints.xs}px) {
          background-size: contain;
          left: -25%;
          bottom: -150px;
        }
      }
    `;
  }}
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 24px;
`;

const StyledTitle = styled(SectionTitle)`
  max-width: 100%;
`;

const CardGrid = styled.div`
  ${({ theme }) => {
    return css`
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      width: 100%;
      max-width: 1152px;
      grid-gap: 24px;

      @media (max-width: ${theme.breakpoints.lg}px) {
        grid-template-columns: 1fr;
      }
    `;
  }}
`;

const Card = styled.div`
  ${({ theme }) => {
    return css`
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 20px 24px;
      box-sizing: border-box;
      gap: 16px;
      background: ${theme.colors.gray2};
      border: 1px solid ${theme.colors.gray6};
      border-radius: 8px;
      width: 100%;
    `;
  }}
`;

const MainInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 5px 0px 0px;
  gap: 8px;
`;

const CardTitle = styled.h5`
  ${({ theme }) => {
    return css`
      all: unset;
      color: ${theme.colors.gray12};
      ${typography.title20};

      @media (max-width: ${theme.breakpoints.sm}px) {
        ${typography.title18};
      }
    `;
  }}
`;

const CardDescription = styled.p`
  ${({ theme }) => {
    return css`
      all: unset;
      color: ${theme.colors.gray11};
      ${typography.textBaseLight};
    `;
  }}
`;

const IconBox = styled.div`
  ${({ theme }) => {
    return css`
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 0px;
      isolation: isolate;
      box-sizing: border-box;
      white-space: nowrap;
      flex-shrink: 0;
      width: 36px;
      height: 36px;
      background-color: ${theme.colors.gray4};
      border: 1px solid ${theme.colors.gray8};
      border-radius: 8px;
    `;
  }}
`;
