import React, { Suspense } from "react";
import { Switch, Route } from "react-router";
import { ROUTES } from "routes/constants";

import GlobalLoading from "components/LoadingAnimations/GlobalLoading";

// Homepage
import BeamPage from "screens/Homepage/Beam";
const LazyLoadedRoutes = React.lazy(() => import("./LazyLoadedRoutes"));

const HomepageRoutes: React.FC = () => {
  return (
    <Switch>
      <Route exact path={ROUTES.beam} component={BeamPage} />
      <Route
        path="/"
        render={() => (
          <Suspense fallback={<GlobalLoading />}>
            <LazyLoadedRoutes />
          </Suspense>
        )}
      />
    </Switch>
  );
};

export default HomepageRoutes;
