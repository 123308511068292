import { useEffect, useState } from "react";
import { Fade } from "react-awesome-reveal";
import styled, { css, keyframes } from "styled-components";
import { Root, Item, Header, Trigger, Content } from "@radix-ui/react-accordion";
import { Badge, CodeWindow } from "@beamcloud/design-system";
import { typography } from "screens/Homepage/components/typography";

export interface IBuildingBlock {
  type: string;
  title: string;
  items: {
    name: string;
    description: any;
    illustration?: {
      layout?: "1" | "2";
      code?: string;
      imageSrc: string;
    };
  }[];
  accordionHeight?: number; // avoid layout shifts when longer contents are expanded
}

interface IProps extends IBuildingBlock {}

const BuildingBlock: React.FC<IProps> = ({ type, title, items, accordionHeight }) => {
  const [selectedItem, setSelectedItems] = useState<number>(-1);

  useEffect(() => {
    if (items.length > 0) {
      setSelectedItems(0);
    }
  }, [items]);

  const onValueChange = (value: string) => {
    setSelectedItems(items.findIndex(item => item.name === value));
  };

  return (
    <Container>
      <MainContent>
        <Badge color="blue11" textVariant="textBasePlus">
          {type}
        </Badge>
        <BuildingBlockTitle>{title}</BuildingBlockTitle>
        {selectedItem >= 0 && (
          <Accordion
            type="single"
            value={items[selectedItem].name}
            onValueChange={onValueChange}
            $height={accordionHeight}
          >
            {items.map(({ name, description }, key) => (
              <AccordionItem key={key} value={name}>
                <AccordionHeader>
                  <AccordionTrigger>{name}</AccordionTrigger>
                </AccordionHeader>
                <AccordionContent>{description}</AccordionContent>
              </AccordionItem>
            ))}
          </Accordion>
        )}
      </MainContent>
      {selectedItem >= 0 && (
        <StyledFade>
          <Illustration data-layout={items[selectedItem].illustration?.layout ?? "1"}>
            <Image
              src={items[selectedItem].illustration?.imageSrc}
              alt={title}
              data-layout={items[selectedItem].illustration?.layout ?? "1"}
            />
            {items[selectedItem].illustration?.code ? (
              <CodeEditor
                content={items[selectedItem].illustration!.code as string}
                tabs={["app.py"]}
                data-layout={items[selectedItem].illustration?.layout ?? "1"}
              />
            ) : null}
          </Illustration>
        </StyledFade>
      )}
    </Container>
  );
};

export default BuildingBlock;

const Container = styled.div`
  ${({ theme }) => {
    return css`
      display: grid;
      gap: 20px 48px;
      align-items: start;
      justify-items: start;
      width: 100%;
      max-width: 1152px;
      grid-template-columns: 1fr;

      @media (min-width: ${theme.breakpoints.lg}px) {
        grid-template-columns: 1fr 1fr;
      }
    `;
  }}
`;

const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 24px 0px 0px;
  width: 100%;
`;

const Illustration = styled.div`
  ${({ theme }) => css`
    position: relative;
    z-index: 0;
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: 552px;
    height: auto;

    &:is([data-layout="1"]) {
      aspect-ratio: 552 / 448;
    }

    &:is([data-layout="2"]) {
      &:has(${CodeEditor}) {
        margin-bottom: max(calc(224px - calc(100% / 1.38) * 0.44), 48px);
      }
    }
  `}
`;

const Image = styled.img`
  ${({ theme }) => css`
    z-index: 0;

    background-color: ${theme.colors.gray2};
    border: 1px solid ${theme.colors.gray3};
    border-radius: 8px;

    position: relative;
    object-fit: contain;
    width: 100%;
    margin-bottom: auto;

    &:is([data-layout="1"]) {
      position: absolute;
      top: 0px;
      left: 0px;
      width: calc(100% - 48px);
      height: calc(100% - 48px);
      @media (min-width: ${theme.breakpoints.lg}px) and (max-width: ${theme.breakpoints.xl}px) {
        width: calc(100% - 32px);
        height: calc(100% - 32px);
      }
      @media (max-width: ${theme.breakpoints.sm}px) {
        width: calc(100% - 16px);
        height: calc(100% - 16px);
      }
    }
  `}
`;

const CodeEditor = styled(CodeWindow)`
  && {
    ${({ theme }) => css`
      position: absolute;
      z-index: 0;
      background-color: rgba(32, 34, 39, 0.64);
      backdrop-filter: blur(8px);
      height: 224px;

      &:is([data-layout="1"]) {
        bottom: 0;
        right: 0;
        width: calc(100% - 48px);
        @media (min-width: ${theme.breakpoints.lg}px) and (max-width: ${theme.breakpoints.xl}px) {
          width: calc(100% - 32px);
        }
        @media (max-width: ${theme.breakpoints.sm}px) {
          width: calc(100% - 16px);
        }
      }

      &:is([data-layout="2"]) {
        top: 56%;
        left: 50%;
        transform: translate(-50%, 0);
        width: calc(100% - (48px * 2));
        @media (min-width: ${theme.breakpoints.lg}px) and (max-width: ${theme.breakpoints.xl}px) {
          width: calc(100% - (32px * 2));
        }
        @media (max-width: ${theme.breakpoints.xs}px) {
          width: calc(100% - (16px * 2));
        }
      }
    `}
  }
`;

const BuildingBlockTitle = styled.h3`
  ${({ theme }) => {
    return css`
      all: unset;
      color: ${theme.colors.gray12};
      width: 100%;
      margin-top: 12px;
      margin-bottom: 32px;

      ${typography.title28};

      @media (min-width: ${theme.breakpoints.sm}px) {
        ${typography.title36};
      }
    `;
  }}
`;

const Accordion = styled(Root)<{ $height?: number }>`
  ${({ $height }) => {
    return css`
      min-height: ${$height ? `${$height}px` : "auto"};
    `;
  }}
`;

const AccordionItem = styled(Item)`
  ${({ theme }) => {
    return css`
      box-sizing: border-box;
      padding: 0px 0px 0px 24px;
      border-left: 2px solid transparent;
      overflow: hidden;

      :first-child {
        margin-top: 0;
      }

      :not(:last-child) {
        margin-bottom: 24px;
      }

      &[data-state="open"] {
        border-left-color: ${theme.colors.blue9};
      }
    `;
  }}
`;

const AccordionHeader = styled(Header)`
  ${({ theme }) => {
    return css`
      all: unset;
      display: flex;
      ${theme.styleSet.textLgPlus};
      color: ${theme.colors.gray12};
      margin-bottom: 4px;
    `;
  }}
`;

const AccordionTrigger = styled(Trigger)`
  all: unset;
  cursor: pointer;
`;

const AccordionContent = styled(Content)`
  ${({ theme }) => {
    return css`
      ${typography.textBaseLight};
      color: ${theme.colors.gray11};
      overflow: scroll;

      &[data-state="open"] {
        animation: ${slideDown} 300ms cubic-bezier(0.87, 0, 0.13, 1);
      }

      &[data-state="closed"] {
        animation: ${slideUp} 300ms cubic-bezier(0.87, 0, 0.13, 1);
      }
    `;
  }}
`;

const slideDown = keyframes`
  from {
    height: 0;
  }
  to {
    height: var(--radix-accordion-content-height);
  }
`;

const slideUp = keyframes`
  from {
    height: var(--radix-accordion-content-height);
  }
  to {
    height: 0;
  }
`;

const StyledFade = styled(Fade).attrs({
  delay: 250,
  duration: 500,
  triggerOnce: true
})`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
`;
