import globalConfigDev from "../ConfigGlobalDev.json";
import globalConfigStaging from "../ConfigGlobalStaging.json";
import globalConfigProd from "../ConfigGlobalProd.json";
import packageJson from "../../package.json";

export interface IFeatureFlags {
  environmentModal: boolean;
  debugMode: boolean;
  languageServer: boolean;
  asyncTraining: boolean;
  getStartedChecklist: boolean;
  gitSubmodules: boolean;
  agents: boolean;
}
export interface ConfigGlobal {
  readonly disableEmailSignup: boolean;
  readonly version: string;
  readonly notebookBaseUrl: string;
  readonly apiBaseUrl: string;
  readonly beamApiBaseUrl: string;
  readonly modelBaseUrl: string;
  readonly beamBaseUrl: string;
  readonly realtimeServerBaseUrl: string;
  readonly stripeKey: string;
  readonly stripeClientId: string;
  readonly featureFlags: IFeatureFlags;
  readonly sentryUrl: string | null;
  readonly maxUploadSize: number;
  readonly logrocketUrl: string;
  readonly googleAnalyticsID: string;
  readonly crispKey: string;
  readonly sanityProjectID: string;
}

//
// NOTE: The global config file is static and compiled into the JS bundle.
// Because the file is included in the bundle, as code, it's loading is synchronous and immediate,
// not async.  This makes the global config a little easier to work with than if it was opened
// as a user file.
//

class ConfigGlobalLoader {
  private static configGlobal: ConfigGlobal;

  private static Load(): ConfigGlobal {
    const version = packageJson.version || "1.0.0";
    let config: ConfigGlobal = {
      ...globalConfigDev,
      version
    };

    if (process.env.REACT_APP_ENV === "local") {
      config = {
        ...globalConfigDev,
        version
      };
    } else if (process.env.REACT_APP_ENV === "staging") {
      config = {
        ...globalConfigStaging,
        version: "staging-" + version
      };
    } else if (process.env.REACT_APP_ENV === "prod") {
      config = {
        ...globalConfigProd,
        version: "prod-" + version
      };
    }

    if (process.env.REACT_APP_ENV === "local") {
      const namespace = process.env.REACT_APP_OKTETO_NAMESPACE;
      for (var prop in config) {
        // @ts-ignore
        let _value = config[prop];
        if (typeof _value === "string") {
          // @ts-ignore
          config[prop] = _value.replace(/{}/g, namespace || "");
        }
      }
    }

    return config;
  }

  public static get config(): ConfigGlobal {
    if (ConfigGlobalLoader.configGlobal === undefined) {
      ConfigGlobalLoader.configGlobal = ConfigGlobalLoader.Load();
    }
    return ConfigGlobalLoader.configGlobal;
  }
}

export default ConfigGlobalLoader;
