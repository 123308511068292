import { useEffect, useState } from "react";
import { useLocalStorage } from "react-use";
import ConfigGlobalLoader from "services/config";
import moment from "moment-timezone";

import { GlobalContext } from "./context";

// Theme
import { ThemeProvider } from "styled-components";
// Use ThemeProvider from @beamcloud/design-system is to correctly style exported components from the package
import { ThemeProvider as ThemeContainer, defaultTheme } from "@beamcloud/design-system";

import AuthProvider from "store/Auth/AuthProvider";

interface IProps {
  children: React.ReactNode;
}

export const GlobalContextProvider = ({ children }: IProps) => {
  const [accessToken, setAccessToken, removeAccessToken] = useLocalStorage<string>("authToken");
  const [pendingApiCalls, setPendingApiCalls] = useState<number>(0);

  // Set callbacks for API service
  useEffect(() => {
    const userTimeZone = moment.tz.guess();
    moment.tz.setDefault(userTimeZone);
  }, []);

  return (
    <GlobalContext.Provider
      value={{
        accessToken,
        setAccessToken,
        removeAccessToken,
        pendingApiCalls,
        setPendingApiCalls
      }}
    >
      {/* ThemeContainer wraps ThemeProvider so that default component styles from the packages can be overriden */}
      <ThemeProvider theme={defaultTheme}>
        <ThemeContainer>
          <AuthProvider>{children}</AuthProvider>
        </ThemeContainer>
      </ThemeProvider>
    </GlobalContext.Provider>
  );
};
