import { useEffect } from "react";
import { Slide } from "react-toastify";

// Contexts & hooks
import useAuthContext from "store/Auth/useAuthContext";
import { useGlobalContext } from "store/Global/context";
import ToastContainer from "components/ToastContainer";

// Routes & Screens
import Routes from "routes";

// Components
import APIService from "services/api/base";

const App = () => {
  const { accessToken } = useGlobalContext();
  const { getUserInfo, getIdentityQuota } = useAuthContext();

  useEffect(() => {
    APIService.setAuthToken(accessToken);
    getUserInfo();
    getIdentityQuota();
  }, [accessToken]);

  return (
    <>
      <Routes />
      <ToastContainer transition={Slide} />
    </>
  );
};

export default App;

// Export icon library globally, so that the Icon component from @beamcloud/design-system can use it
export * from "components/Icon";
